import "./App.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Login from "./pages/Login/Login";
import Profile from "./pages/Profile/Profile";
import Signup from "./pages/Signup/Signup";
import ToDos from "./pages/ToDos/ToDos";
import TeamToDos from "./pages/TeamToDos/TeamToDos";
import Projects from "./pages/Projects/Projects";
import Users from "./pages/Users/Users";
import Calendar from "./pages/Calendar/Calendar";
import TeamCalendar from "./pages/TeamCalendar/TeamCalendar";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/Profile" element={<Header />} />
          <Route path="/Profile/:UserID" element={<Header />} />
          <Route path="/To-Dos" element={<Header />} />
          <Route path="/To-Dos/:UserID" element={<Header />} />
          <Route path="/Team-To-Dos" element={<Header />} />
          <Route path="/Team-To-Dos/:UserID" element={<Header />} />
          <Route path="/Projects" element={<Header />} />
          <Route path="/Projects/:UserID" element={<Header />} />
          <Route path="/Users" element={<Header />} />
          <Route path="/Users/:UserID" element={<Header />} />
          <Route path="/Calendar" element={<Header />} />
          <Route path="/Calendar/:UserID" element={<Header />} />
          <Route path="/Team-Calendar" element={<Header />} />
          <Route path="/Team-Calendar/:UserID" element={<Header />} />
        </Routes>
        <Routes>
          <Route path="/" element={<Navigate to={"/Login"} />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Profile/:UserID" element={<Profile />} />
          <Route path="/To-Dos" element={<ToDos />} />
          <Route path="/To-Dos/:UserID" element={<ToDos />} />
          <Route path="/Team-To-Dos" element={<TeamToDos />} />
          <Route path="/Team-To-Dos/:UserID" element={<TeamToDos />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Projects/:UserID" element={<Projects />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/Users/:UserID" element={<Users />} />
          <Route path="/Calendar" element={<Calendar />} />
          <Route path="/Calendar/:UserID" element={<Calendar />} />
          <Route path="/Team-Calendar" element={<TeamCalendar />} />
          <Route path="/Team-Calendar/:UserID" element={<TeamCalendar />} />
        </Routes>
        <Routes>
          <Route path="/Profile" element={<Footer />} />
          <Route path="/Profile/:UserID" element={<Footer />} />
          <Route path="/To-Dos" element={<Footer />} />
          <Route path="/To-Dos/:UserID" element={<Footer />} />
          <Route path="/Team-To-Dos" element={<Footer />} />
          <Route path="/Team-To-Dos/:UserID" element={<Footer />} />
          <Route path="/Projects" element={<Footer />} />
          <Route path="/Projects/:UserID" element={<Footer />} />
          <Route path="/Users" element={<Footer />} />
          <Route path="/Users/:UserID" element={<Footer />} />
          <Route path="/Calendar" element={<Footer />} />
          <Route path="/Calendar/:UserID" element={<Footer />} />
          <Route path="/Team-Calendar" element={<Footer />} />
          <Route path="/Team-Calendar/:UserID" element={<Footer />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
