import "./Login.scss";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import { postLogin, getProfile } from "../../apiUtil/apiUtil";

const Login = () => {
  const Navigate = useNavigate();

  const [error, setError] = useState<string>("");

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // console.log(event.target.email.value, event.target.password.value);
    try {
      const { data } = await postLogin(
        event.target.email.value,
        event.target.password.value
      );
      // console.log(data);
      sessionStorage.setItem("token", data.token);
      const token = sessionStorage.getItem("token");
      const user = await getProfile(token);
      Navigate(`/Profile/${user.data.id}`);
    } catch (error: any) {
      setError(error.response.data);
    }
  };

  return (
    <main className="login-page">
      <div className="login-page__sub-section">
        <div className="login-page__sub-container d-flex align-items-center py-4 bg-body-tertiary">
          <form onSubmit={handleSubmit} className="login-page__form">
            <h1 className="login-page__sub-header">Enter your credentials</h1>
            <div className="login-page__form-container">
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  name="email"
                  required
                />
                <label>Email address</label>
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  name="password"
                  required
                />
                <label>Password</label>
              </div>
            </div>
            <button
              className="login__btn btn btn-primary w-100 py-2"
              type="submit"
            >
              Sign in
            </button>
            <p>
              Need an account? <Link to="/Signup">Sign up</Link>
            </p>
            {error && <div className="login__message">{error}</div>}
          </form>
        </div>
      </div>
    </main>
  );
};

export default Login;
