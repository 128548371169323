import axios from "axios";

const { REACT_APP_API_URL } = process.env;
const API_URL: string | undefined = REACT_APP_API_URL;

const postLogin = async (email: string, password: string) => {
  console.log(`${API_URL}/users/login`);
  return axios.post(`${API_URL}/users/login`, {
    email,
    password,
  });
};

const postSignup = async (
  email: string,
  password: string,
  first_name: string,
  last_name: string,
  phone: string,
  address: string
) => {
  return axios.post(`${API_URL}/users/register`, {
    email,
    password,
    first_name,
    last_name,
    phone,
    address,
  });
};

const getProfile = async (token: string | null) => {
  return axios.get(`${API_URL}/users/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const patchProfile = async (token: string | null, file: any) => {
  return axios.patch(`${API_URL}/users/edit`, {
    file,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const editProfile = async (
  token: string | null,
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  phone: string,
  address: string,
  role_access: string,
  classification_code: string
) => {
  return axios.patch(`${API_URL}/users/editProfile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    id,
    first_name,
    last_name,
    email,
    phone,
    address,
    role_access,
    classification_code,
  });
};

const deleteProfile = async (token: string | null, id: number) => {
  return axios.delete(`${API_URL}/users/deleteProfile/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const passwordProfile = async (
  token: string | null,
  id: number,
  current_password: string,
  new_password: string
) => {
  return axios.patch(`${API_URL}/users/passwordProfile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    id,
    current_password,
    new_password,
  });
};

const getUsers = async (token: string | null) => {
  return axios.get(`${API_URL}/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getProjects = async (token: string | null) => {
  return axios.get(`${API_URL}/project/get`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const postProjects = async (
  token: string | null,
  project_name: string,
  project_manager_id: number
) => {
  return axios.post(`${API_URL}/project/add`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    project_manager_id,
    state: false,
    delete_state: false,
    project_name,
    description: "",
  });
};

const deleteProjects = async (token: string | null, id: number) => {
  return axios.delete(`${API_URL}/project/delete/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getComments = async (token: string | null) => {
  return axios.get(`${API_URL}/comment/get`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const postComments = async (
  token: string | null,
  comment: string,
  user_id: number,
  project_id: number,
  date: string
) => {
  return axios.post(`${API_URL}/comment/add`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    comment,
    user_id,
    project_id,
    date,
  });
};

const postTask = async (
  token: string | null,
  project_id: number,
  user_request_id: number,
  user_assigned_id: number,
  hours: number,
  due_date: string,
  task_description: string
) => {
  return axios.post(`${API_URL}/task/add`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    project_id,
    user_request_id,
    user_assigned_id,
    hours,
    due_date,
    task_description,
    state: false,
    pending: true,
    accepted: false,
    rejected: false,
    completed: false,
  });
};

const getTask = async (token: string | null) => {
  return axios.get(`${API_URL}/task/get`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const editTask = async (
  token: string | null,
  id: number,
  state: boolean,
  user_id: number,
  project_id: number
) => {
  return axios.patch(`${API_URL}/task/edit`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    id,
    state,
    user_id,
    project_id,
  });
};

const acceptTask = async (token: string | null, id: number) => {
  return axios.patch(`${API_URL}/task/accept`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    id,
    accepted: true,
    pending: false,
    completed: false,
    state: false,
    rejected: false,
  });
};

const completeTask = async (token: string | null, id: number) => {
  return axios.patch(`${API_URL}/task/complete`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    id,
    accepted: false,
    pending: false,
    completed: true,
    state: true,
    rejected: false,
  });
};

const rejectTask = async (token: string | null, id: number) => {
  return axios.patch(`${API_URL}/task/reject`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    id,
    accepted: false,
    pending: false,
    completed: false,
    state: false,
    rejected: true,
  });
};

const saveTask = async (
  token: string | null,
  id: number,
  hours: number,
  due_date: string,
  task_description: string
) => {
  return axios.patch(`${API_URL}/task/save`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    id,
    hours,
    due_date,
    task_description,
    accepted: false,
    pending: true,
    completed: false,
    state: false,
    rejected: false,
  });
};

const deleteTask = async (token: string | null, id: number) => {
  return axios.delete(`${API_URL}/task/delete/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export {
  postSignup,
  postLogin,
  getProfile,
  patchProfile,
  editProfile,
  deleteProfile,
  passwordProfile,
  getUsers,
  getProjects,
  postProjects,
  deleteProjects,
  getComments,
  postComments,
  postTask,
  getTask,
  editTask,
  acceptTask,
  completeTask,
  rejectTask,
  saveTask,
  deleteTask,
};
