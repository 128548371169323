import "./Projects.scss";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getProfile,
  getUsers,
  getProjects,
  postProjects,
  deleteProjects,
  postComments,
  getComments,
  postTask,
  getTask,
  editTask,
} from "../../apiUtil/apiUtil";
import projects from "../../JSON/projects.json";
import comments from "../../JSON/comments.json";
import task from "../../JSON/tasks.json";
import userIcon from "../../assets/icons/user.svg";
import { cl } from "@fullcalendar/core/internal-common";

const Projects = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const Navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [allUser, setAllUser] = useState<any>([]);
  const [failedAuth, setFailedAuth] = useState<boolean>(false);
  const { UserID } = useParams();
  const [projectList, setProjectList] = useState<any>([]);
  const [commentsList, setCommentsList] = useState<any>([]);
  const [assignTask, setAssignTask] = useState<any>({
    hours: "",
    task_description: "",
    due_date: "",
    find_user: "",
    find_userID: "",
  });
  const [addProject, setAddProject] = useState<any>("");
  const [userComment, setUserComment] = useState<any>("");
  const [filterProjects, setFilterProjects] = useState<any>("");
  const [taskList, setTaskList] = useState<any>([]);
  const [taskState, setTaskState] = useState<any>(false);
  const [sortBy, setSortBy] = useState<any>("All Projects");
  const [sortByProjects, setSortByProjects] = useState<any>(null);
  const [showAddProject, setShowAddProject] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [addProjectValidated, setAddProjectValidated] =
    useState<boolean>(false);
  const [addCommentValidated, setAddCommentValidated] =
    useState<boolean>(false);

  const handleClose = (id: number): void => {
    // console.log(showList);
    const selectedTask = projectList.filter(
      (selectedID: any) => selectedID.id === id
    );
    // console.log(selectedTask);
    selectedTask[0].state = false;
    const otherTasks = projectList.filter(
      (selectedID: any) => selectedID.id !== id
    );
    // console.log(otherTasks);
    setProjectList([...otherTasks, selectedTask[0]]);
    setAssignTask({
      task_description: "",
      due_date: "",
      find_user: "",
      find_userID: "",
    });
    setUserComment("");
    setValidated(false);
    setAddCommentValidated(false);
    setShowDropdown(false);
  };

  const handleShowModalDelete = (id: number): void => {
    // console.log(showList);
    const selectedTask = projectList.filter(
      (selectedID: any) => selectedID.id === id
    );
    // console.log(selectedTask);
    selectedTask[0].delete_state = true;
    const otherTasks = projectList.filter(
      (selectedID: any) => selectedID.id !== id
    );
    // console.log(otherTasks);
    // Fetch project data based on ID and show modal
    setProjectList([...otherTasks, selectedTask[0]]);
  };

  const handleCloseModalDelete = (id: number): void => {
    // console.log(showList);
    const selectedTask = projectList.filter(
      (selectedID: any) => selectedID.id === id
    );
    // console.log(selectedTask);
    selectedTask[0].delete_state = false;
    const otherTasks = projectList.filter(
      (selectedID: any) => selectedID.id !== id
    );
    // console.log(otherTasks);
    // Fetch project data based on ID and show modal
    setProjectList([...otherTasks, selectedTask[0]]);
  };

  const handleDeleteProjectFunction = async (id: number) => {
    // Delete request on backend
    const token: string | null = sessionStorage.getItem("token");
    // console.log(id);

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Add new project
      await deleteProjects(token, id);
      handleCloseModalDelete(id);
      handleClose(id);
      const { data } = await getProjects(token);
      // console.log(data);
      setProjectList(data);
      // console.log(data);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleShowModal = (id: number): void => {
    // console.log(showList);
    const selectedTask = projectList.filter(
      (selectedID: any) => selectedID.id === id
    );
    // console.log(selectedTask);
    selectedTask[0].state = true;
    const otherTasks = projectList.filter(
      (selectedID: any) => selectedID.id !== id
    );
    // console.log(otherTasks);
    // Fetch project data based on ID and show modal
    setProjectList([...otherTasks, selectedTask[0]]);
  };

  const handleCloseAddProject = () => {
    setShowAddProject(false);
    setAddProject("");
    setAddProjectValidated(false);
  };
  const handleShowAddProject = () => setShowAddProject(true);

  const handleAddProjectFunction = async (project_name: string) => {
    const token: string | null = sessionStorage.getItem("token");
    // console.log(project_name);

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Add new project
      await postProjects(token, project_name, Number(UserID));
      handleCloseAddProject();
      const { data } = await getProjects(token);
      // console.log(data);
      setProjectList(data);
      setAddProjectValidated(true);
      // console.log(data);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleTaskList = async (id: number, state: boolean) => {
    const selectedTaskList = taskList.filter(
      (selectedID: any) => selectedID.id === id
    );
    selectedTaskList[0].state = !state;
    // console.log(selectedTaskList);
    // const otherTasks = taskList.filter(
    //   (selectedID: any) => selectedID.id !== id
    // );
    // console.log(taskState);
    // setTaskState([...otherTasks, selectedTaskList[0]]);
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Add new project
      await editTask(
        token,
        selectedTaskList[0].id,
        selectedTaskList[0].state,
        Number(UserID),
        selectedTaskList[0].project_id
      );
      const { data } = await getTask(token);
      // console.log(data);
      setTaskList(data);
      // console.log(data);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  // Re-render each time UserID in URL changes
  useEffect(() => {
    const getProfileFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getProfile(token);
        // console.log(data);
        // Checks if token is expired
        if (data.error === "token expired") {
          sessionStorage.removeItem("token");
          Navigate("/Login");
        }
        // If user tries to manual change :UserID in URL, display Login link
        if (Number(UserID) !== data.id) {
          setFailedAuth(true);
        } else {
          setFailedAuth(false);
        }
        setUser(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getUsersFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getUsers(token);
        // console.log(data);
        setAllUser(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getProjectsFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getProjects(token);
        // console.log(data);
        setProjectList(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getCommentsFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getComments(token);
        // console.log(data);
        setCommentsList(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getTaskFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getTask(token);
        // console.log(data);
        setTaskList(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    getProfileFunction();
    getUsersFunction();
    getProjectsFunction();
    getCommentsFunction();
    getTaskFunction();
  }, [UserID]);

  const findUserProfile = (user_id: any): any => {
    const profile = allUser.find((user: any) => {
      return user.id === user_id;
    });
    // console.log(profile);
    // Checks if user exists in database
    if (profile === undefined) {
      return null;
    }
    return profile.profile;
  };

  const findUserComment = (user_id: any): any => {
    const profile = allUser.find((user: any) => {
      return user.id === user_id;
    });
    // console.log(profile);
    // Checks if user exists in database
    if (profile === undefined) {
      return null;
    }
    return `${profile.first_name} ${profile.last_name}`;
  };

  const handleAssignTask = async (id: number) => {
    const token: string | null = sessionStorage.getItem("token");
    // console.log(assignTask);
    // console.log(id);

    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Retrieve profile data
      await postTask(
        token,
        id,
        Number(UserID),
        assignTask.find_userID,
        assignTask.hours,
        assignTask.due_date,
        assignTask.task_description
      );
      const { data } = await getTask(token);
      // console.log(data);
      setTaskList(data);
      setAssignTask({
        hours: "",
        task_description: "",
        due_date: "",
        find_user: "",
        find_userID: "",
      });
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleAddComment = async (id: number) => {
    const token: string | null = sessionStorage.getItem("token");

    // Create a date object from a date string
    const date = new Date();

    // Get year, month, and day part from the date
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    const formattedDate = year + "-" + month + "-" + day;
    // console.log(formattedDate); // Prints: 2022-05-04

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Retrieve profile data
      await postComments(token, userComment, Number(UserID), id, formattedDate);
      const { data } = await getComments(token);
      // console.log(data);
      setCommentsList(data);
      setUserComment("");
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleSortBy = (sort: any) => {
    setSortBy(sort.target.value);
  };

  const handleSubmit = (event: any, id: number) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true && assignTask.find_userID !== "") {
      event.preventDefault();
      event.stopPropagation();
      handleAssignTask(Number(id));
      setValidated(false);
    } else {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleAddProjectSubmit = (event: any) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      handleAddProjectFunction(addProject);
      setAddProjectValidated(false);
    } else {
      setAddProjectValidated(true);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleAddCommentSubmit = (event: any, id: number) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      handleAddComment(Number(id));
      setAddCommentValidated(false);
    } else {
      setAddCommentValidated(true);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);

  if (!user) {
    return <main className="projects-page">Loading ...</main>;
  }

  if (failedAuth) {
    return (
      <main className="projects-page">
        <p>You must be logged in to see this page.</p>
        <Link to={"/Login"}>Log In</Link>
      </main>
    );
  }

  // console.log(showList);
  // console.log(allUser);
  // console.log(assignTask);
  // console.log(assignTask.task_description);
  // console.log(assignTask.date_from);
  // console.log(assignTask.date_to);
  // console.log(assignTask.find_user);
  // console.log(assignTask.find_userID);
  // console.log(sortBy);
  // console.log(sortByProjects);
  // console.log(projects);
  // console.log(projectList);
  // console.log(taskList);

  return (
    <main className="projects-page">
      <h1 className="projects-page__header">Projects</h1>
      <div className="projects-page__search-container">
        <Button variant="primary" onClick={handleShowAddProject}>
          Add Project
        </Button>
        <Modal show={showAddProject} onHide={handleCloseAddProject}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="featurette-divider" />
            <h6>Enter Information</h6>
            <hr className="featurette-divider" />
            <Form
              noValidate
              validated={addProjectValidated}
              onSubmit={(e) => {
                handleAddProjectSubmit(e);
              }}
            >
              <Form.Group className="mb-3" controlId="addProjectValue">
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Project Name"
                  value={addProject}
                  onChange={(event: any) => {
                    // console.log(event);
                    setAddProject(event.target.value);
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a project name.
                </Form.Control.Feedback>
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                // onClick={() => {
                //   handleAddProjectFunction(addProject);
                // }}
              >
                Add Project
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAddProject}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Form>
          <Form.Group>
            <Form.Control
              type="search"
              placeholder="Search Project"
              className="projects-page__search-project"
              value={filterProjects.find_project}
              onChange={(event) => {
                setFilterProjects({
                  ...filterProjects,
                  find_project: event.target.value,
                });
              }}
              name="search"
              required
            />
          </Form.Group>
        </Form>
      </div>
      <div className="projects-page__sort-by">
        <Form>
          <Form.Label>Sort By:</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="sort"
            id="sort"
            value={sortBy}
            onChange={(event) => {
              handleSortBy(event);
            }}
          >
            <option value={`All Projects`}>Alphabet - All Projects</option>
            <option value={`Your Projects`}>Alphabet - Your Projects</option>
          </Form.Select>
        </Form>
        {/* <select
          name="sort"
          id="sort"
          value={sortBy}
          onChange={(event) => {
            handleSortBy(event);
          }}
        ></select> */}
      </div>
      <Row xs={3} md={3} className="g-4">
        {projectList
          .filter((projectSort: any) => {
            if (sortBy === "All Projects") {
              return projectSort;
            }
            if (sortBy === "Your Projects") {
              return projectSort.project_manager_id === Number(UserID);
            }
          })
          .filter((projectFind: any) => {
            let temp = 0;
            if (filterProjects.find_project === undefined) {
              return projectFind;
            }
            for (let i = 0; i < filterProjects.find_project.length; i++) {
              if (
                projectFind.project_name[i] === filterProjects.find_project[i]
              ) {
                temp++;
              }
            }
            // console.log(temp);
            // console.log(projectFind.length);
            return temp === filterProjects.find_project.length;
          })
          .sort((a: any, b: any) => {
            if (a.project_name < b.project_name) {
              return -1;
            }
            if (a.project_name > b.project_name) {
              return 1;
            }
            return 0;
          })
          .map(
            ({
              id,
              project_manager_id,
              state,
              delete_state,
              project_name,
              description,
            }: {
              id: number;
              project_manager_id: number;
              state: boolean;
              delete_state: boolean;
              project_name: string;
              description: string;
            }) => (
              <Col key={id}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={userIcon}
                    className="projects-page__img"
                  />
                  <Card.Body>
                    <Card.Title>{project_name}</Card.Title>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        handleShowModal(Number(id));
                      }}
                      id={`${id}`}
                    >
                      View
                    </Button>

                    <Modal
                      show={Boolean(state)}
                      onHide={() => {
                        handleClose(Number(id));
                      }}
                      size="lg"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{project_name}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form>
                          <hr className="featurette-divider" />
                          <h6>Task List</h6>
                          <hr className="featurette-divider" />
                          <ul>
                            {taskList.filter(
                              (task: any) => task.project_id === id
                            ).length === 0 ? (
                              <li>No Tasks Assigned</li>
                            ) : (
                              taskList
                                .filter((task: any) => {
                                  if (
                                    Boolean(task.accepted) === true ||
                                    Boolean(task.completed) === true
                                  ) {
                                    return task.project_id === id;
                                  }
                                })
                                .map((task: any): any => {
                                  return (
                                    <>
                                      <li
                                        key={task.id}
                                        className={`${
                                          Boolean(task.state) === true
                                            ? "projects-page__Completed"
                                            : "projects-page__task"
                                        } mb-3 projects-page__task-container`}
                                      >
                                        <div className="projects-page__task-list">
                                          {/* <input
                                            type="checkbox"
                                            checked={Boolean(task.state)}
                                            onChange={() => {
                                              handleTaskList(
                                                Number(task.id),
                                                Boolean(task.state)
                                              );
                                            }}
                                          /> */}
                                          <Form.Check // prettier-ignore
                                            type={"checkbox"}
                                            checked={Boolean(task.state)}
                                            id={`${task.id}`}
                                            label={`${task.task_description}`}
                                            onChange={() => {
                                              handleTaskList(
                                                Number(task.id),
                                                Boolean(task.state)
                                              );
                                            }}
                                            disabled={
                                              project_manager_id ===
                                                Number(UserID) ||
                                              user.role_access === "admin"
                                                ? false
                                                : true
                                            }
                                            className={`${
                                              Boolean(task.state) === true
                                                ? "projects-page__Completed"
                                                : "projects-page__task"
                                            } mb-3 projects-page__task-container`}
                                          />
                                          {/* {task.task_description} */}
                                        </div>
                                        <div className="projects-page__task-date">
                                          {allUser
                                            .filter((user: any) => {
                                              return (
                                                user.id ===
                                                task.user_assigned_id
                                              );
                                            })
                                            .map((filteredUser: any) => {
                                              return `${filteredUser.first_name} ${filteredUser.last_name},`;
                                            })}
                                          <div>{task.due_date}</div>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })
                            )}
                          </ul>
                        </Form>
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={(e) => {
                            handleSubmit(e, Number(id));
                          }}
                        >
                          <hr className="featurette-divider" />
                          <h6>Assign Task</h6>
                          <hr className="featurette-divider" />
                          <Form.Group
                            className="projects-page__assign-task"
                            controlId="taskValue"
                          >
                            <Form.Label>Task Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Task Description"
                              value={assignTask.task_description}
                              onChange={(event) => {
                                setAssignTask({
                                  ...assignTask,
                                  task_description: event.target.value,
                                });
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a task.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="projects-page__assign-task"
                            controlId="hoursValue"
                          >
                            <Form.Label>Hours</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Hours"
                              value={assignTask.hours}
                              onChange={(event) => {
                                setAssignTask({
                                  ...assignTask,
                                  hours: event.target.value,
                                });
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide hours.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="projects-page__assign-task"
                            controlId="dueDateValue"
                          >
                            <Form.Label>Due Date</Form.Label>
                            <Form.Control
                              type="date"
                              name="date_to"
                              value={assignTask.due_date}
                              onChange={(event) => {
                                setAssignTask({
                                  ...assignTask,
                                  due_date: event.target.value,
                                });
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a due date.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="projects-page__assign-task"
                            controlId="UserValue"
                          >
                            <Form.Label>Find User</Form.Label>
                            <Form.Control
                              type="search"
                              placeholder="Search User"
                              className="search"
                              value={assignTask.find_user}
                              onChange={(event) => {
                                setAssignTask({
                                  ...assignTask,
                                  find_user: event.target.value,
                                  find_userID: "",
                                });
                              }}
                              name="search"
                              required
                              isInvalid={
                                assignTask.find_userID === "" &&
                                assignTask.find_user.length > 0
                              }
                              onFocus={() => {
                                setShowDropdown(true);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please select a valid user.
                            </Form.Control.Feedback>
                          </Form.Group>
                          {showDropdown && (
                            <Dropdown.Menu
                              show={
                                assignTask.find_user.length === 0 ? false : true
                              }
                            >
                              {allUser
                                .filter((userFind: any) => {
                                  let temp = 0;
                                  for (
                                    let i = 0;
                                    i < assignTask.find_user.length;
                                    i++
                                  ) {
                                    if (
                                      userFind.first_name[i] ===
                                      assignTask.find_user[i]
                                    ) {
                                      temp++;
                                    }
                                  }
                                  // console.log(temp);
                                  // console.log(findUser.length);
                                  return assignTask.find_user.length === 0
                                    ? undefined
                                    : temp === assignTask.find_user.length;
                                })
                                .map((user: any) => {
                                  return (
                                    <Dropdown.Item
                                      key={user.id}
                                      onClick={() => {
                                        setAssignTask({
                                          ...assignTask,
                                          find_user: `${user.first_name} ${user.last_name}`,
                                          find_userID: user.id,
                                        });
                                        setShowDropdown(false);
                                      }}
                                    >
                                      <img
                                        src={
                                          findUserProfile(user.id) === null
                                            ? userIcon
                                            : `${API_URL}/${findUserProfile(
                                                user.id
                                              )}`
                                        }
                                        alt="profile"
                                        className="projects-page__Comment-Img"
                                      />
                                      {user.first_name} {user.last_name}
                                    </Dropdown.Item>
                                  );
                                })}
                            </Dropdown.Menu>
                          )}

                          {/* <Button
                            variant="primary"
                            onClick={() => {
                              handleAssignTask(Number(id));
                            }}
                          >
                            Assign Task
                          </Button> */}
                          <Button
                            type="submit"
                            disabled={
                              project_manager_id === Number(UserID) ||
                              user.role_access === "admin"
                                ? false
                                : true
                            }
                            className="projects-page__dropdown-button"
                          >
                            Request Task
                          </Button>
                        </Form>
                        <Form
                          noValidate
                          validated={addCommentValidated}
                          onSubmit={(e) => {
                            handleAddCommentSubmit(e, id);
                          }}
                        >
                          <hr className="featurette-divider" />
                          <h6>Chat History</h6>
                          {/* <hr className="featurette-divider" /> */}
                          <div className="projects-page__Comment-Section">
                            {commentsList.filter(
                              (comments: any) => comments.project_id === id
                            ).length === 0 ? (
                              <>
                                <hr className="featurette-divider" />
                                <li>No Comments</li>
                              </>
                            ) : (
                              commentsList
                                .filter(
                                  (comments: any) => comments.project_id === id
                                )
                                .map(
                                  ({
                                    id,
                                    project_id,
                                    user_id,
                                    comment,
                                    date,
                                  }: {
                                    id: number;
                                    project_id: number;
                                    user_id: number;
                                    comment: string;
                                    date: string;
                                  }) => (
                                    <>
                                      <hr className="featurette-divider" />
                                      <div
                                        key={id}
                                        className="projects-page__Comment"
                                      >
                                        <hr className="featurette-divider" />
                                        <img
                                          src={
                                            findUserProfile(user_id) === null
                                              ? userIcon
                                              : `${API_URL}/${findUserProfile(
                                                  user_id
                                                )}`
                                          }
                                          alt="profile"
                                          className="projects-page__Comment-Img"
                                        />
                                        <div className="projects-page__Comment-user">
                                          <div className="projects-page__Comment-user-container">
                                            <h6 className="projects-page__Comment-user-font">
                                              {findUserComment(user_id)}
                                            </h6>
                                            <h6>{date}</h6>
                                          </div>
                                          <p className="projects-page__Comment-comment-font">
                                            {comment}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )
                            )}
                          </div>

                          <hr className="featurette-divider" />

                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={userComment}
                              onChange={(event: any) => {
                                setUserComment(event.target.value);
                              }}
                              required
                              type="text"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid comment.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            variant="primary"
                            type="submit"
                            // onClick={() => {
                            //   handleAddComment(Number(id));
                            // }}
                          >
                            Add Comment
                          </Button>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="danger"
                          onClick={() => {
                            handleShowModalDelete(Number(id));
                          }}
                          disabled={
                            project_manager_id === Number(UserID) ||
                            user.role_access === "admin"
                              ? false
                              : true
                          }
                        >
                          Delete
                        </Button>
                        <Modal
                          show={delete_state}
                          onHide={() => {
                            handleCloseModalDelete(Number(id));
                          }}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Delete Project</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <p>
                              Are you sure you want to delete{" "}
                              <strong>{project_name}</strong>? This action
                              cannot be undone.
                            </p>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={() => {
                                handleCloseModalDelete(Number(id));
                              }}
                            >
                              Close
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => {
                                handleDeleteProjectFunction(Number(id));
                              }}
                              disabled={
                                project_manager_id === Number(UserID) ||
                                user.role_access === "admin"
                                  ? false
                                  : true
                              }
                            >
                              Delete Project
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            handleClose(Number(id));
                          }}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Card.Body>
                </Card>
              </Col>
            )
          )}
      </Row>
    </main>
  );
};

export default Projects;
