import "./ToDos.scss";
import { getProfile, getTask } from "../../apiUtil/apiUtil";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listWeek from "@fullcalendar/list";
import weekGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import projects from "../../JSON/projects.json";
import comments from "../../JSON/comments.json";
import task from "../../JSON/tasks.json";
import axios from "axios";
import {
  getProjects,
  editTask,
  getUsers,
  acceptTask,
  completeTask,
  rejectTask,
  saveTask,
  deleteTask,
} from "../../apiUtil/apiUtil";

const ToDos = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const Navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [allUser, setAllUser] = useState<any>([]);
  const [taskList, setTaskList] = useState<any>([]);
  const [projectList, setProjectList] = useState<any>([]);
  const [failedAuth, setFailedAuth] = useState<boolean>(false);
  const { UserID } = useParams();
  const [validated, setValidated] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<any>("All Tasks");

  useEffect(() => {
    const getProfileFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getProfile(token);
        // console.log(data);
        // Checks if token is expired
        if (data.error === "token expired") {
          sessionStorage.removeItem("token");
          Navigate("/Login");
        }
        // If user tries to manual change :UserID in URL, display Login link
        if (Number(UserID) !== data.id) {
          setFailedAuth(true);
        } else {
          setFailedAuth(false);
        }
        setUser(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getTaskFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getTask(token);
        // console.log(data);
        setTaskList(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getProjectsFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getProjects(token);
        // console.log(data);
        setProjectList(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getUsersFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getUsers(token);
        // console.log(data);
        setAllUser(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    getProjectsFunction();
    getProfileFunction();
    getTaskFunction();
    getUsersFunction();
  }, [UserID]);

  // Component to represent the due date table list
  const [dueDateList, setDueDateList] = useState<any>([]);
  const [editingId, setEditingId] = useState<any>(null);
  const [edits, setEdits] = useState<any>(null);
  const [newTask, setNewTask] = useState<any>("");
  const [newDueDate, setNewDueDate] = useState<any>("");

  const handleComplete = async (id: number) => {
    // const selectedTaskList = taskList.filter(
    //   (selectedID: any) => selectedID.id === id
    // );
    // selectedTaskList[0].state = true;
    // console.log(selectedTaskList);
    // const otherTasks = taskList.filter(
    //   (selectedID: any) => selectedID.id !== id
    // );
    // console.log(taskState);
    // setTaskState([...otherTasks, selectedTaskList[0]]);
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Add new project
      await completeTask(token, id);
      const { data } = await getTask(token);
      // console.log(data);
      setTaskList(data);
      // console.log(data);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleEdit = (
    id: any,
    hours: number,
    due_date: string,
    task_description: string
  ) => {
    setEditingId(id);
    setEdits({ hours, due_date, task_description });
  };

  const handleCancel = (id: any) => {
    setEditingId(null);
    setEdits(null);
  };

  const handleAccept = async (id: any) => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Add new project
      await acceptTask(token, id);
      const { data } = await getTask(token);
      // console.log(data);
      setTaskList(data);
      // console.log(data);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleSave = async (
    id: number,
    updatedHours: number,
    updatedDueDate: string,
    updatedTask: string
  ) => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }
    try {
      await saveTask(token, id, updatedHours, updatedDueDate, updatedTask);
      // Update the task in the local state
      const { data } = await getTask(token);
      setTaskList(data);
      setEditingId(null);
      setEdits(null);
    } catch (error) {
      // console.error("Error updating task:", error);
    }
  };

  const handleDelete = async (id: any) => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Add new project
      await deleteTask(token, id);
      const { data } = await getTask(token);
      // console.log(data);
      setTaskList(data);
      // console.log(data);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleReject = async (id: any) => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Add new project
      await rejectTask(token, id);
      const { data } = await getTask(token);
      // console.log(data);
      setTaskList(data);
      // console.log(data);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleSubmit = (event: any, id: number) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const findProject = (task: any) => {
    for (var i = 0; i < projectList.length; i++) {
      if (projectList[i].id === task.project_id) {
        return projectList[i].project_name;
      }
    }
  };

  const findUser = (user: any) => {
    for (var i = 0; i < allUser.length; i++) {
      if (allUser[i].id === user) {
        return `${allUser[i].first_name} ${allUser[i].last_name}`;
      }
    }
  };

  const handleSortBy = (sort: any) => {
    setSortBy(sort.target.value);
  };

  if (!user) {
    return <main className="todos-page">Loading ...</main>;
  }

  if (failedAuth) {
    return (
      <main className="todos-page">
        <p>You must be logged in to see this page.</p>
        <Link to={"/Login"}>Log In</Link>
      </main>
    );
  }

  // console.log(user.id);

  return (
    <main className="todos-page">
      <h1 className="todos-page__sub-section">To-Dos</h1>
      <div className="todos-page__sort-by">
        <Form>
          <Form.Label>Sort By:</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="sort"
            id="sort"
            value={sortBy}
            onChange={(event) => {
              handleSortBy(event);
            }}
          >
            <option value={`All Tasks`}>All Tasks</option>
            <option value={`Your Tasks`}>Your Tasks</option>
            <option value={`Your Incoming Task Requests`}>
              Your Incoming Task Requests
            </option>
            <option value={`Your Pending Task Requests`}>
              Your Pending Task Requests
            </option>
            <option value={`Your Accepted Task Requests`}>
              Your Accepted Task Requests
            </option>
            <option value={`Your Rejected Task Requests`}>
              Your Rejected Task Requests
            </option>
            <option value={`Your Completed Task Requests`}>
              Your Completed Task Requests
            </option>
          </Form.Select>
        </Form>
      </div>
      {(sortBy === "All Tasks" || sortBy === "Your Tasks") && (
        <Row xs={1} md={2} className="g-4 todos-page__card">
          {Array.from({ length: 1 }).map((_, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Body>
                  <Card.Title>Your Tasks</Card.Title>
                  <div>
                    <Table striped bordered hover size="xl" responsive="xl">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Task</th>
                          <th>Hours Committed</th>
                          <th>Requested By</th>
                          <th>Due Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskList
                          .filter((task: any) => {
                            if (
                              Boolean(task.accepted) === true &&
                              Number(UserID) === task.user_assigned_id
                            ) {
                              return task;
                            }
                          })
                          .sort((a: any, b: any) => {
                            if (a.due_date < b.due_date) {
                              return -1;
                            }
                            if (a.due_date > b.due_date) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((item: any) => (
                            <tr key={item.id}>
                              <td>{findProject(item)}</td>
                              <td>{item.task_description}</td>
                              <td>{item.hours}</td>
                              <td>{findUser(item.user_request_id)}</td>
                              <td>{item.due_date}</td>
                              <td>
                                <Button onClick={() => handleComplete(item.id)}>
                                  Complete
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {(sortBy === "All Tasks" || sortBy === "Your Incoming Task Requests") && (
        <Row xs={1} md={2} className="g-4 todos-page__card">
          {Array.from({ length: 1 }).map((_, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Body>
                  <Card.Title>Your Incoming Task Requests</Card.Title>
                  <div>
                    <Table striped bordered hover size="xl" responsive="xl">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Task</th>
                          <th>Hours Committed</th>
                          <th>Requested By</th>
                          <th>Due Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskList
                          .filter((task: any) => {
                            if (
                              Boolean(task.pending) === true &&
                              Number(UserID) === task.user_assigned_id
                            ) {
                              return task;
                            }
                          })
                          .sort((a: any, b: any) => {
                            if (a.due_date < b.due_date) {
                              return -1;
                            }
                            if (a.due_date > b.due_date) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((item: any) => (
                            <tr key={item.id}>
                              <td>{findProject(item)}</td>
                              <td>{item.task_description}</td>
                              <td>{item.hours}</td>
                              <td>{findUser(item.user_request_id)}</td>
                              <td>{item.due_date}</td>
                              <td>
                                <Button onClick={() => handleAccept(item.id)}>
                                  Accept
                                </Button>

                                <Button
                                  variant="danger"
                                  onClick={() => handleReject(item.id)}
                                >
                                  Reject
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {(sortBy === "All Tasks" || sortBy === "Your Pending Task Requests") && (
        <Row xs={1} md={2} className="g-4 todos-page__card">
          {Array.from({ length: 1 }).map((_, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Body>
                  <Card.Title>Your Pending Task Requests</Card.Title>
                  <div>
                    <Table striped bordered hover size="xl" responsive="xl">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Task</th>
                          <th>Hours Committed</th>
                          <th>Assigned To</th>
                          <th>Due Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskList
                          .filter((task: any) => {
                            if (
                              Boolean(task.pending) === true &&
                              Number(UserID) === task.user_request_id
                            ) {
                              return task;
                            }
                          })
                          .sort((a: any, b: any) => {
                            if (a.due_date < b.due_date) {
                              return -1;
                            }
                            if (a.due_date > b.due_date) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((item: any) => (
                            <tr key={item.id}>
                              <td>{findProject(item)}</td>
                              <td>{item.task_description}</td>
                              <td>{item.hours}</td>
                              <td>{findUser(item.user_assigned_id)}</td>
                              <td>{item.due_date}</td>
                              <td>Pending</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {(sortBy === "All Tasks" || sortBy === "Your Accepted Task Requests") && (
        <Row xs={1} md={2} className="g-4 todos-page__card">
          {Array.from({ length: 1 }).map((_, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Body>
                  <Card.Title>Your Accepted Task Requests</Card.Title>
                  <div>
                    <Table striped bordered hover size="xl" responsive="xl">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Task</th>
                          <th>Hours Committed</th>
                          <th>Accepted By</th>
                          <th>Due Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskList
                          .filter((task: any) => {
                            if (
                              Boolean(task.accepted) === true &&
                              Number(UserID) === task.user_request_id
                            ) {
                              return task;
                            }
                          })
                          .sort((a: any, b: any) => {
                            if (a.due_date < b.due_date) {
                              return -1;
                            }
                            if (a.due_date > b.due_date) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((item: any) => (
                            <tr key={item.id}>
                              <td>{findProject(item)}</td>
                              <td>{item.task_description}</td>
                              <td>{item.hours}</td>
                              <td>{findUser(item.user_assigned_id)}</td>
                              <td>{item.due_date}</td>
                              <td>Accepted</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {(sortBy === "All Tasks" || sortBy === "Your Rejected Task Requests") && (
        <Row xs={1} md={2} className="g-4 todos-page__card">
          {Array.from({ length: 1 }).map((_, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Body>
                  <Card.Title>Your Rejected Task Requests</Card.Title>
                  <div>
                    <Table striped bordered hover size="xl" responsive="xl">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Task</th>
                          <th>Hours Commited</th>
                          <th>Rejected By</th>
                          <th>Due Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskList
                          .filter((task: any) => {
                            if (
                              Boolean(task.rejected) === true &&
                              Number(UserID) === task.user_request_id
                            ) {
                              return task;
                            }
                          })
                          .sort((a: any, b: any) => {
                            if (a.due_date < b.due_date) {
                              return -1;
                            }
                            if (a.due_date > b.due_date) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((item: any) => (
                            <tr key={item.id}>
                              {/* <Form
                              noValidate
                              validated={validated}
                              onSubmit={(e) => {
                                handleSubmit(e, Number(item.id));
                              }}
                            > */}
                              <td>{findProject(item)}</td>
                              <td>
                                {editingId === item.id ? (
                                  <Form.Group
                                    className="mb-3"
                                    controlId="editTaskValue"
                                  >
                                    {/* <input
                                      type="text"
                                      value={edits.task_description}
                                      onChange={(e) => {
                                        setEdits({
                                          ...edits,
                                          task_description: e.target.value,
                                        });
                                      }}
                                    /> */}
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Task Name"
                                      value={edits.task_description}
                                      onChange={(e) => {
                                        setEdits({
                                          ...edits,
                                          task_description: e.target.value,
                                        });
                                      }}
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please provide a task description.
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                ) : (
                                  item.task_description
                                )}
                              </td>
                              <td>
                                {editingId === item.id ? (
                                  // <input
                                  //   type="number"
                                  //   value={edits.hours}
                                  // onChange={(e) => {
                                  //   setEdits({
                                  //     ...edits,
                                  //     hours: Number(e.target.value),
                                  //   });
                                  // }}
                                  // />
                                  <Form.Group
                                    className="mb-3"
                                    controlId="editHoursValue"
                                  >
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter Hours"
                                      value={edits.hours}
                                      onChange={(e) => {
                                        setEdits({
                                          ...edits,
                                          hours: Number(e.target.value),
                                        });
                                      }}
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please provide hours
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted"></Form.Text>
                                  </Form.Group>
                                ) : (
                                  item.hours
                                )}
                              </td>
                              <td>{findUser(item.user_assigned_id)}</td>
                              <td>
                                {editingId === item.id ? (
                                  // <input
                                  //   type="date"
                                  //   value={edits.due_date}
                                  // onChange={(e) => {
                                  //   setEdits({
                                  //     ...edits,
                                  //     due_date: e.target.value,
                                  //   });
                                  // }}
                                  // />
                                  <Form.Group
                                    className="mb-3"
                                    controlId="addProjectValue"
                                  >
                                    <Form.Control
                                      type="date"
                                      placeholder="Enter Project Name"
                                      value={edits.due_date}
                                      onChange={(e) => {
                                        setEdits({
                                          ...edits,
                                          due_date: e.target.value,
                                        });
                                      }}
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please provide a valid date.
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted"></Form.Text>
                                  </Form.Group>
                                ) : (
                                  item.due_date
                                )}
                              </td>
                              <td>
                                {editingId === item.id ? (
                                  <Button
                                    onClick={() =>
                                      handleSave(
                                        item.id,
                                        edits.hours,
                                        edits.due_date,
                                        edits.task_description
                                      )
                                    }
                                  >
                                    Re-Send
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      handleEdit(
                                        item.id,
                                        item.hours,
                                        item.due_date,
                                        item.task_description
                                      )
                                    }
                                  >
                                    Edit
                                  </Button>
                                )}

                                {editingId === item.id ? (
                                  <Button
                                    variant="secondary"
                                    onClick={() => handleCancel(item.id)}
                                  >
                                    Cancel
                                  </Button>
                                ) : (
                                  <Button
                                    variant="danger"
                                    onClick={() => handleDelete(item.id)}
                                  >
                                    Delete
                                  </Button>
                                )}
                              </td>
                              {/* </Form> */}
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {(sortBy === "All Tasks" ||
        sortBy === "Your Completed Task Requests") && (
        <Row xs={1} md={2} className="g-4 todos-page__card">
          {Array.from({ length: 1 }).map((_, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Body>
                  <Card.Title>Your Completed Task Requests</Card.Title>
                  <div>
                    <Table striped bordered hover size="xl" responsive="xl">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Task</th>
                          <th>Hours Committed</th>
                          <th>Completed By</th>
                          <th>Due Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskList
                          .filter((task: any) => {
                            if (
                              Boolean(task.completed) === true &&
                              Number(UserID) === task.user_request_id
                            ) {
                              return task;
                            }
                          })
                          .sort((a: any, b: any) => {
                            if (a.due_date < b.due_date) {
                              return -1;
                            }
                            if (a.due_date > b.due_date) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((item: any) => (
                            <tr key={item.id}>
                              <td>{findProject(item)}</td>
                              <td>{item.task_description}</td>
                              <td>{item.hours}</td>
                              <td>{findUser(item.user_assigned_id)}</td>
                              <td>{item.due_date}</td>
                              <td>Completed</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </main>
  );
};

export default ToDos;
