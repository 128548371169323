import "./Calendar.scss";
import { getProfile, getTask } from "../../apiUtil/apiUtil";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listWeek from "@fullcalendar/list";
import weekGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import projects from "../../JSON/projects.json";
import comments from "../../JSON/comments.json";
import task from "../../JSON/tasks.json";
import axios from "axios";
import {
  getProjects,
  editTask,
  getUsers,
  acceptTask,
  completeTask,
  rejectTask,
  saveTask,
  deleteTask,
} from "../../apiUtil/apiUtil";

const Calendar = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const Navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [allUser, setAllUser] = useState<any>([]);
  const [taskList, setTaskList] = useState<any>([]);
  const [projectList, setProjectList] = useState<any>([]);
  const [failedAuth, setFailedAuth] = useState<boolean>(false);
  const { UserID } = useParams();
  const events: any = [];
  const [validated, setValidated] = useState<boolean>(false);

  const [showCalendarModal, setShowCalendarModal] = useState<boolean>(false);
  const [modalProjectName, setModalProjectName] = useState<any>("");
  const [modalTaskDescription, setModalTaskDescription] = useState<any>("");
  const [modalDueDate, setModalDueDate] = useState<any>("");
  const [modalAssignedUser, setModalAssignedUser] = useState<any>("");
  const [modalRequestUser, setModalRequestUser] = useState<any>("");

  const handleCloseCalendarModal = () => setShowCalendarModal(false);
  const handleShowCalendarModal = () => setShowCalendarModal(true);

  useEffect(() => {
    const getProfileFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getProfile(token);
        // console.log(data);
        // Checks if token is expired
        if (data.error === "token expired") {
          sessionStorage.removeItem("token");
          Navigate("/Login");
        }
        // If user tries to manual change :UserID in URL, display Login link
        if (Number(UserID) !== data.id) {
          setFailedAuth(true);
        } else {
          setFailedAuth(false);
        }
        setUser(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getTaskFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getTask(token);
        // console.log(data);
        setTaskList(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getProjectsFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getProjects(token);
        // console.log(data);
        setProjectList(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getUsersFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getUsers(token);
        // console.log(data);
        setAllUser(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    getProjectsFunction();
    getProfileFunction();
    getTaskFunction();
    getUsersFunction();
  }, [UserID]);

  // a custom render function
  function renderEventContent(eventInfo: any) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  const handleTask = () => {
    for (let i = 0; i < taskList.length; i++) {
      if (
        taskList[i].user_assigned_id === user.id &&
        Boolean(taskList[i].accepted) === true
      ) {
        // console.log(taskList[i].task_description);

        const project = findProject(taskList[i]);
        const assignedUser = findAssignedUser(taskList[i]);
        const requestUser = findRequestUser(taskList[i]);

        if (taskList === undefined) {
          window.location.reload();
        }

        if (project === undefined) {
          window.location.reload();
        }

        if (assignedUser === undefined) {
          window.location.reload();
        }

        if (requestUser === undefined) {
          window.location.reload();
        }

        events.push({
          title: `${project.project_name}\n${taskList[i].task_description}\n${taskList[i].due_date}\n${assignedUser.first_name} ${assignedUser.last_name}\n${requestUser.first_name} ${requestUser.last_name}`,
          start: taskList[i].due_date,
        });
      }
    }
    // console.log(events);
    return events;
  };

  const findProject = (task: any) => {
    for (var i = 0; i < projectList.length; i++) {
      if (projectList[i].id === task.project_id) {
        return projectList[i];
      }
    }
  };

  const findAssignedUser = (user: any) => {
    for (var i = 0; i < allUser.length; i++) {
      if (allUser[i].id === user.user_assigned_id) {
        return allUser[i];
      }
    }
  };

  const findRequestUser = (user: any) => {
    for (var i = 0; i < allUser.length; i++) {
      if (allUser[i].id === user.user_request_id) {
        return allUser[i];
      }
    }
  };

  if (!user) {
    return <main className="calendar-page">Loading ...</main>;
  }

  if (failedAuth) {
    return (
      <main className="calendar-page">
        <p>You must be logged in to see this page.</p>
        <Link to={"/Login"}>Log In</Link>
      </main>
    );
  }

  // console.log(user.id);

  return (
    <main className="calendar-page">
      <h1 className="calendar-page__sub-section">Calendar</h1>
      <Row xs={1} md={2} className="g-4 calendar-page__card">
        {Array.from({ length: 1 }).map((_, idx) => (
          <Col key={idx}>
            <Card>
              <Card.Body>
                <Card.Title>Weekly View</Card.Title>
                <div>
                  <FullCalendar
                    plugins={[
                      dayGridPlugin,
                      weekGridPlugin,
                      listWeek,
                      interactionPlugin,
                    ]}
                    initialView="dayGridWeek"
                    handleWindowResize={true}
                    expandRows={true}
                    weekends={true}
                    events={handleTask()}
                    eventContent={renderEventContent}
                    eventClick={(info) => {
                      // alert(info.event.title);

                      let projectName = "";
                      let taskDescription = "";
                      let dueDate = "";
                      let assignedUser = "";
                      let requestUser = "";
                      let pos = 0;

                      for (let i = pos; i < info.event.title.length; i++) {
                        if (info.event.title[i] === "\n") {
                          pos = i + 1;
                          break;
                        }
                        projectName += info.event.title[i];
                      }

                      for (let i = pos; i < info.event.title.length; i++) {
                        if (info.event.title[i] === "\n") {
                          pos = i + 1;
                          break;
                        }
                        taskDescription += info.event.title[i];
                      }

                      for (let i = pos; i < info.event.title.length; i++) {
                        if (info.event.title[i] === "\n") {
                          pos = i + 1;
                          break;
                        }
                        dueDate += info.event.title[i];
                      }

                      for (let i = pos; i < info.event.title.length; i++) {
                        if (info.event.title[i] === "\n") {
                          pos = i + 1;
                          break;
                        }
                        assignedUser += info.event.title[i];
                      }

                      for (let i = pos; i < info.event.title.length; i++) {
                        if (info.event.title[i] === "\n") {
                          pos = i + 1;
                          break;
                        }
                        requestUser += info.event.title[i];
                      }

                      setModalProjectName(projectName);
                      setModalTaskDescription(`${taskDescription}`);
                      setModalDueDate(`${dueDate}`);
                      setModalAssignedUser(`${assignedUser}`);
                      setModalRequestUser(`${requestUser}`);
                      handleShowCalendarModal();
                      // change the border color just for fun
                      // info.el.style.borderColor = "red";
                    }}
                    selectable={true}
                    dateClick={(info: any) => {
                      // console.log("Clicked on: " + info.dateStr);
                      // console.log(
                      //   "Coordinates: " +
                      //     info.jsEvent.pageX +
                      //     "," +
                      //     info.jsEvent.pageY
                      // );
                      // change the day's background color just for fun
                      // info.dayEl.style.backgroundColor = "red";
                      // console.log("Current view: " + info.view.type);
                    }}
                  />
                </div>
                <Modal
                  show={showCalendarModal}
                  onHide={handleCloseCalendarModal}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{modalProjectName}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h6>Task Description:</h6>
                    <p>{modalTaskDescription}</p>
                    <h6>Due Date:</h6>
                    <p>{modalDueDate}</p>
                    <h6>Assigned To:</h6>
                    <p>{modalAssignedUser}</p>
                    <h6>Requested By:</h6>
                    <p>{modalRequestUser}</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleCloseCalendarModal}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </main>
  );
};

export default Calendar;
