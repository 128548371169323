import "./TeamToDos.scss";
import { getProfile, getTask } from "../../apiUtil/apiUtil";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listWeek from "@fullcalendar/list";
import weekGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import projects from "../../JSON/projects.json";
import comments from "../../JSON/comments.json";
import task from "../../JSON/tasks.json";
import axios from "axios";
import userIcon from "../../assets/icons/user.svg";
import {
  getProjects,
  editTask,
  getUsers,
  acceptTask,
  completeTask,
  rejectTask,
  saveTask,
  deleteTask,
} from "../../apiUtil/apiUtil";

const TeamToDos = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const Navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [allUser, setAllUser] = useState<any>([]);
  const [filterUsers, setFilterUsers] = useState<any>({
    find_user: "",
    find_userID: "",
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [taskList, setTaskList] = useState<any>([]);
  const [projectList, setProjectList] = useState<any>([]);
  const [failedAuth, setFailedAuth] = useState<boolean>(false);
  const { UserID } = useParams();
  const [validated, setValidated] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<any>("All Tasks");

  useEffect(() => {
    const getProfileFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getProfile(token);
        // console.log(data);
        // Checks if token is expired
        if (data.error === "token expired") {
          sessionStorage.removeItem("token");
          Navigate("/Login");
        }
        // If user tries to manual change :UserID in URL, display Login link
        if (Number(UserID) !== data.id) {
          setFailedAuth(true);
        } else {
          setFailedAuth(false);
        }
        setUser(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getTaskFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getTask(token);
        // console.log(data);
        setTaskList(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getProjectsFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getProjects(token);
        // console.log(data);
        setProjectList(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getUsersFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getUsers(token);
        // console.log(data);
        setAllUser(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    getProjectsFunction();
    getProfileFunction();
    getTaskFunction();
    getUsersFunction();
  }, [UserID]);

  // Component to represent the due date table list
  const [dueDateList, setDueDateList] = useState<any>([]);
  const [editingId, setEditingId] = useState<any>(null);
  const [edits, setEdits] = useState<any>(null);
  const [newTask, setNewTask] = useState<any>("");
  const [newDueDate, setNewDueDate] = useState<any>("");

  const handleComplete = async (id: number) => {
    // const selectedTaskList = taskList.filter(
    //   (selectedID: any) => selectedID.id === id
    // );
    // selectedTaskList[0].state = true;
    // console.log(selectedTaskList);
    // const otherTasks = taskList.filter(
    //   (selectedID: any) => selectedID.id !== id
    // );
    // console.log(taskState);
    // setTaskState([...otherTasks, selectedTaskList[0]]);
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Add new project
      await completeTask(token, id);
      const { data } = await getTask(token);
      // console.log(data);
      setTaskList(data);
      // console.log(data);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleEdit = (
    id: any,
    hours: number,
    due_date: string,
    task_description: string
  ) => {
    setEditingId(id);
    setEdits({ hours, due_date, task_description });
  };

  const handleCancel = (id: any) => {
    setEditingId(null);
    setEdits(null);
  };

  const handleAccept = async (id: any) => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Add new project
      await acceptTask(token, id);
      const { data } = await getTask(token);
      // console.log(data);
      setTaskList(data);
      // console.log(data);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleSave = async (
    id: number,
    updatedHours: number,
    updatedDueDate: string,
    updatedTask: string
  ) => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }
    try {
      await saveTask(token, id, updatedHours, updatedDueDate, updatedTask);
      // Update the task in the local state
      const { data } = await getTask(token);
      setTaskList(data);
      setEditingId(null);
      setEdits(null);
    } catch (error) {
      // console.error("Error updating task:", error);
    }
  };

  const handleDelete = async (id: any) => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Add new project
      await deleteTask(token, id);
      const { data } = await getTask(token);
      // console.log(data);
      setTaskList(data);
      // console.log(data);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleReject = async (id: any) => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Add new project
      await rejectTask(token, id);
      const { data } = await getTask(token);
      // console.log(data);
      setTaskList(data);
      // console.log(data);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleSubmit = (event: any, id: number) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const findProject = (task: any) => {
    for (var i = 0; i < projectList.length; i++) {
      if (projectList[i].id === task.project_id) {
        return projectList[i].project_name;
      }
    }
  };

  const findUser = (user: any) => {
    for (var i = 0; i < allUser.length; i++) {
      if (allUser[i].id === user) {
        return `${allUser[i].first_name} ${allUser[i].last_name}`;
      }
    }
  };

  const handleSortBy = (sort: any) => {
    setSortBy(sort.target.value);
  };

  const findUserProfile = (user_id: any): any => {
    const profile = allUser.find((user: any) => {
      return user.id === user_id;
    });
    // console.log(profile);
    // Checks if user exists in database
    if (profile === undefined) {
      return null;
    }
    return profile.profile;
  };

  if (!user) {
    return <main className="todos-page">Loading ...</main>;
  }

  if (failedAuth) {
    return (
      <main className="todos-page">
        <p>You must be logged in to see this page.</p>
        <Link to={"/Login"}>Log In</Link>
      </main>
    );
  }

  // console.log(user.id);

  return (
    <main className="todos-page">
      <h1 className="todos-page__sub-section">Team To-Dos</h1>
      <div className="todos-page__sort-by">
        <Form>
          <Form.Label>Sort By:</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="sort"
            id="sort"
            value={sortBy}
            onChange={(event) => {
              handleSortBy(event);
            }}
          >
            <option value={`All Tasks`}>All Tasks</option>
            <option value={`User`}>User</option>
          </Form.Select>
        </Form>
        {sortBy === "User" && (
          <Form>
            <Form.Group
              className="projects-page__assign-task"
              controlId="UserValue"
            >
              <Form.Label>Find User</Form.Label>
              <Form.Control
                type="search"
                placeholder="Search User"
                className="search"
                value={filterUsers.find_user}
                onChange={(event) => {
                  setFilterUsers({
                    ...filterUsers,
                    find_user: event.target.value,
                    find_userID: "",
                  });
                }}
                name="search"
                required
                isInvalid={
                  filterUsers.find_userID === "" &&
                  filterUsers.find_user.length > 0
                }
                onFocus={() => {
                  setShowDropdown(true);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please select a valid user.
              </Form.Control.Feedback>
            </Form.Group>
            {showDropdown && (
              <Dropdown.Menu
                show={filterUsers.find_user.length === 0 ? false : true}
              >
                {allUser
                  .filter((userFind: any) => {
                    let temp = 0;
                    for (let i = 0; i < filterUsers.find_user.length; i++) {
                      if (userFind.first_name[i] === filterUsers.find_user[i]) {
                        temp++;
                      }
                    }
                    // console.log(temp);
                    // console.log(findUser.length);
                    return filterUsers.find_user.length === 0
                      ? undefined
                      : temp === filterUsers.find_user.length;
                  })
                  .map((user: any) => {
                    return (
                      <Dropdown.Item
                        key={user.id}
                        onClick={() => {
                          setFilterUsers({
                            ...filterUsers,
                            find_user: `${user.first_name} ${user.last_name}`,
                            find_userID: user.id,
                          });
                          setShowDropdown(false);
                        }}
                      >
                        <img
                          src={
                            findUserProfile(user.id) === null
                              ? userIcon
                              : `${API_URL}/${findUserProfile(user.id)}`
                          }
                          alt="profile"
                          className="projects-page__Comment-Img"
                        />
                        {user.first_name} {user.last_name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            )}
          </Form>
        )}
      </div>
      {sortBy === "All Tasks" && (
        <Row xs={1} md={2} className="g-4 todos-page__card">
          {Array.from({ length: 1 }).map((_, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Body>
                  <Card.Title>All Tasks</Card.Title>
                  <div>
                    <Table striped bordered hover size="xl" responsive="xl">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Task</th>
                          <th>Hours Committed</th>
                          <th>Accepted By</th>
                          <th>Requested By</th>
                          <th>Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskList
                          .filter((task: any) => {
                            return task;
                          })
                          .sort((a: any, b: any) => {
                            if (a.due_date < b.due_date) {
                              return -1;
                            }
                            if (a.due_date > b.due_date) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((item: any) => (
                            <tr key={item.id}>
                              <td>{findProject(item)}</td>
                              <td>{item.task_description}</td>
                              <td>{item.hours}</td>
                              <td>{findUser(item.user_assigned_id)}</td>
                              <td>{findUser(item.user_request_id)}</td>
                              <td>{item.due_date}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {sortBy === "User" && (
        <Row xs={1} md={2} className="g-4 todos-page__card">
          {Array.from({ length: 1 }).map((_, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Body>
                  <Card.Title>All Tasks</Card.Title>
                  <div>
                    <Table striped bordered hover size="xl" responsive="xl">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Task</th>
                          <th>Hours Committed</th>
                          <th>Accepted By</th>
                          <th>Requested By</th>
                          <th>Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskList
                          .filter((task: any) => {
                            if (filterUsers.find_user.length === 0) {
                              return task;
                            }
                            if (
                              findUser(task.user_assigned_id) ===
                              filterUsers.find_user
                            ) {
                              return task;
                            }
                            let temp = 0;
                            let acceptedUser: any = findUser(
                              task.user_assigned_id
                            );
                            for (
                              let i = 0;
                              i < filterUsers.find_user.length;
                              i++
                            ) {
                              if (
                                filterUsers.find_user[i] === acceptedUser[i]
                              ) {
                                temp++;
                              }
                            }
                            if (temp === filterUsers.find_user.length) {
                              return task;
                            }
                          })
                          .sort((a: any, b: any) => {
                            if (a.due_date < b.due_date) {
                              return -1;
                            }
                            if (a.due_date > b.due_date) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((item: any) => (
                            <tr key={item.id}>
                              <td>{findProject(item)}</td>
                              <td>{item.task_description}</td>
                              <td>{item.hours}</td>
                              <td>{findUser(item.user_assigned_id)}</td>
                              <td>{findUser(item.user_request_id)}</td>
                              <td>{item.due_date}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </main>
  );
};

export default TeamToDos;
