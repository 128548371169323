import "./Header.scss";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getProfile } from "../../apiUtil/apiUtil";
import userIcon from "../../assets/icons/user.svg";

const Header = () => {
  const API_URL = process.env.REACT_APP_URL;
  const APP_API_URL = process.env.REACT_APP_API_URL;
  const location: any = useLocation();
  const { pathname } = location;
  const Navigate = useNavigate();
  const [failedAuth, setFailedAuth] = useState<boolean>(false);
  const [user, setUser] = useState<any | null>(null);
  const [login, setLogin] = useState<string>("Login");
  const [show, setShow] = useState<boolean>(false);
  const { UserID } = useParams();

  const handleClose = () => {
    setShow(false);
    Navigate("/Login");
  };

  const handleLoginToggle = (): void => {
    if (login === "Login") {
      Navigate("/Login");
    } else {
      sessionStorage.removeItem("token");
      setLogin("Login");
      setShow(true);
    }
  };

  const handleIsLoggedIn = (): string => {
    const token: string | null = sessionStorage.getItem("token");
    if (!token) {
      return `${API_URL}`;
    } else {
      return `${API_URL}/Profile/${UserID}`;
    }
  };

  // Re-renders each time location or userID has been changed
  useEffect(() => {
    const loginState = () => {
      // Check if a user is logged in
      const token = sessionStorage.getItem("token");

      // If logged out
      if (!token) {
        setLogin("Login");
      }
      // If logged in
      if (token) {
        setLogin("Logout");
      }
    };
    loginState();
  }, [location, UserID]);

  useEffect(() => {
    const getProfileFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getProfile(token);
        // console.log(data);
        // Checks if token is expired
        if (data.error === "token expired") {
          sessionStorage.removeItem("token");
          Navigate("/Login");
        }
        // If user tries to manual change :UserID in URL, display Login link
        if (Number(UserID) !== data.id) {
          setFailedAuth(true);
        } else {
          setFailedAuth(false);
        }
        setUser(data);
      } catch (error) {
        // console.log(error);
        Navigate("/Login");
      }
    };
    getProfileFunction();
  }, [UserID]);

  if (!user) {
    return <main className="Header">Loading ...</main>;
  }

  return (
    <header>
      {[false].map((expand: any) => (
        <Navbar
          key={expand}
          expand={expand}
          collapseOnSelect
          bg="primary"
          data-bs-theme="dark"
          fixed="top"
        >
          <Container fluid>
            <Navbar.Brand href={`${handleIsLoggedIn()}`}>
              To-Dos-Tracker
            </Navbar.Brand>
            <div className="header__container">
              {" "}
              <img
                src={
                  user.profile === null
                    ? userIcon
                    : `${APP_API_URL}/${user.profile}`
                }
                alt="profile"
                className="Header__img"
              />
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                bg="primary"
                data-bs-theme="dark"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title
                    className="text-white"
                    id={`offcanvasNavbarLabel-expand-${expand}`}
                  >
                    Links
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3 ">
                    <Nav.Link href={`${API_URL}/To-Dos/${UserID}`}>
                      <Link
                        to={`${API_URL}/To-Dos/${UserID}`}
                        className={`nav-link px-2 ${
                          `${API_URL}${pathname}` ===
                          `${API_URL}/To-Dos/${UserID}`
                            ? "text-black"
                            : "text-white"
                        }`}
                      >
                        To-Dos
                      </Link>
                    </Nav.Link>
                    <Nav.Link href={`${API_URL}/Team-To-Dos/${UserID}`}>
                      <Link
                        to={`${API_URL}/Team-To-Dos/${UserID}`}
                        className={`nav-link px-2 ${
                          `${API_URL}${pathname}` ===
                          `${API_URL}/Team-To-Dos/${UserID}`
                            ? "text-black"
                            : "text-white"
                        }`}
                      >
                        Team To-Dos
                      </Link>
                    </Nav.Link>
                    <Nav.Link href={`${API_URL}/Calendar/${UserID}`}>
                      <Link
                        to={`${API_URL}/Calendar/${UserID}`}
                        className={`nav-link px-2 ${
                          `${API_URL}${pathname}` ===
                          `${API_URL}/Calendar/${UserID}`
                            ? "text-black"
                            : "text-white"
                        }`}
                      >
                        Calendar
                      </Link>
                    </Nav.Link>
                    <Nav.Link href={`${API_URL}/Team-Calendar/${UserID}`}>
                      <Link
                        to={`${API_URL}/Team-Calendar/${UserID}`}
                        className={`nav-link px-2 ${
                          `${API_URL}${pathname}` ===
                          `${API_URL}/Team-Calendar/${UserID}`
                            ? "text-black"
                            : "text-white"
                        }`}
                      >
                        Team Calendar
                      </Link>
                    </Nav.Link>
                    <Nav.Link href={`${API_URL}/Projects/${UserID}`}>
                      <Link
                        to={`${API_URL}/Projects/${UserID}`}
                        className={`nav-link px-2 ${
                          `${API_URL}${pathname}` ===
                          `${API_URL}/Projects/${UserID}`
                            ? "text-black"
                            : "text-white"
                        }`}
                      >
                        Projects
                      </Link>
                    </Nav.Link>
                    <Nav.Link href={`${API_URL}/Profile/${UserID}`}>
                      <Link
                        to={`${API_URL}/Profile/${UserID}`}
                        className={`nav-link px-2 ${
                          `${API_URL}${pathname}` ===
                          `${API_URL}/Profile/${UserID}`
                            ? "text-black"
                            : "text-white"
                        }`}
                      >
                        Profile
                      </Link>
                    </Nav.Link>
                    <Nav.Link href={`${API_URL}/Users/${UserID}`}>
                      <Link
                        to={`${API_URL}/Users/${UserID}`}
                        className={`nav-link px-2 ${
                          `${API_URL}${pathname}` ===
                          `${API_URL}/Users/${UserID}`
                            ? "text-black"
                            : "text-white"
                        }`}
                      >
                        Users
                      </Link>
                    </Nav.Link>
                    <Nav.Link
                      className={`nav-link px-2 ${
                        `${API_URL}${pathname}` === `${API_URL}/Login`
                          ? "text-black"
                          : "text-white"
                      }`}
                      onClick={handleLoginToggle}
                    >
                      {login}
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </div>
          </Container>
        </Navbar>
      ))}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>You've logged out successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </header>
  );
};

export default Header;
