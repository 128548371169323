import "./Users.scss";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import {
  getProfile,
  getUsers,
  getProjects,
  postProjects,
  deleteProjects,
  postComments,
  getComments,
  postTask,
  getTask,
  editTask,
  editProfile,
  deleteProfile,
} from "../../apiUtil/apiUtil";
import projects from "../../JSON/projects.json";
import comments from "../../JSON/comments.json";
import task from "../../JSON/tasks.json";
import userIcon from "../../assets/icons/user.svg";
import { cl } from "@fullcalendar/core/internal-common";

const Users = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const Navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState<any>({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    profile: "",
    role_access: "",
    classification_code: "",
  });
  const [allUser, setAllUser] = useState<any>([]);
  const [failedAuth, setFailedAuth] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [roleSortBy, setRoleSortBy] = useState<string>("");
  const [classificationSortBy, setClassificationSortBy] = useState<string>("");
  const { UserID } = useParams();

  // Re-render each time UserID in URL changes
  useEffect(() => {
    const getProfileFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getProfile(token);
        // console.log(data);
        // Checks if token is expired
        if (data.error === "token expired") {
          sessionStorage.removeItem("token");
          Navigate("/Login");
        }
        // If user tries to manual change :UserID in URL, display Login link
        if (Number(UserID) !== data.id) {
          setFailedAuth(true);
        } else {
          setFailedAuth(false);
        }
        setUser(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    const getUsersFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getUsers(token);
        // console.log(data);
        setAllUser(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    getProfileFunction();
    getUsersFunction();
  }, [UserID, selectedUser.id, showEditModal]);

  const handleCloseEditModal = () => {
    setSelectedUser({
      ...selectedUser,
      id: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      profile: "",
    });
    setValidated(false);
    setShowEditModal(false);
  };
  const handleShowEditModal = (
    id: number,
    first_name: string,
    last_name: string,
    email: string,
    phone: number,
    address: string,
    profile: string,
    role_access: string,
    classification_code: string
  ) => {
    setShowEditModal(true);
    setSelectedUser({
      id,
      first_name,
      last_name,
      email,
      phone,
      address,
      profile,
      role_access,
      classification_code,
    });
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = (id: number) => {
    setShowDeleteModal(true);
    const selectedUser = allUser.filter((users: any) => {
      return users.id === id;
    });
    setSelectedUser(selectedUser[0]);
  };

  const handleEditProfile = async (id: number) => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    if (selectedUser.role_access === "") {
      selectedUser.role_access = user.role_access;
    }

    if (selectedUser.classification_code === "") {
      selectedUser.classification_code = user.classification_code;
    }

    try {
      // Retrieve profile data
      await editProfile(
        token,
        selectedUser.id,
        selectedUser.first_name,
        selectedUser.last_name,
        selectedUser.email,
        selectedUser.phone,
        selectedUser.address,
        selectedUser.role_access,
        selectedUser.classification_code
      );
      const { data } = await getUsers(token);
      // console.log(data);
      setAllUser(data);
      handleCloseEditModal();
      // setValidated(false);
      // setShowEditModal(false);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
      window.location.reload();
    }
  };

  const handleDelete = async (id: any) => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Retrieve profile data
      await deleteProfile(token, Number(id));
      const { data } = await getUsers(token);
      setAllUser(data);
      handleCloseDeleteModal();
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleSubmit = (event: any, id: number) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      handleEditProfile(Number(id));
      setValidated(false);
      setShowEditModal(false);
    } else {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleRoleSortBy = (sort: any) => {
    setRoleSortBy(sort.target.value);
    setSelectedUser({
      ...selectedUser,
      role_access: sort.target.value,
    });
  };

  const handleClassificationSortBy = (sort: any) => {
    setClassificationSortBy(sort.target.value);
    setSelectedUser({
      ...selectedUser,
      classification_code: sort.target.value,
    });
  };

  if (!user) {
    return <main className="users-page">Loading ...</main>;
  }

  if (failedAuth) {
    return (
      <main className="users-page">
        <p>You must be logged in to see this page.</p>
        <Link to={"/Login"}>Log In</Link>
      </main>
    );
  }

  return (
    <main className="users-page">
      <h1 className="users-page__header">Users</h1>
      <Row xs={3} md={3} className="g-4">
        {allUser
          .sort((a: any, b: any) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map(
            ({
              id,
              first_name,
              last_name,
              email,
              phone,
              address,
              profile,
              role_access,
              classification_code,
            }: {
              id: number;
              first_name: string;
              last_name: string;
              email: string;
              phone: number;
              address: string;
              profile: string;
              role_access: string;
              classification_code: string;
            }) => (
              <Col key={id}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={profile === null ? userIcon : `${API_URL}/${profile}`}
                    className="users-page__img"
                  />
                  <Card.Body>
                    <Card.Title>
                      {first_name} {last_name}
                    </Card.Title>
                    <div className="users-page__buttons">
                      {user.role_access === "admin" && (
                        <Button
                          onClick={() => {
                            handleShowEditModal(
                              id,
                              first_name,
                              last_name,
                              email,
                              phone,
                              address,
                              profile,
                              role_access,
                              classification_code
                            );
                          }}
                        >
                          Edit
                        </Button>
                      )}
                      {user.role_access === "admin" && (
                        <Button
                          onClick={() => {
                            handleShowDeleteModal(Number(id));
                          }}
                          variant="danger"
                          className="users-page__buttons-danger"
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                    <Modal show={showEditModal} onHide={handleCloseEditModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Profile</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={(e) => {
                            handleSubmit(e, Number(UserID));
                          }}
                        >
                          <hr className="featurette-divider" />
                          <h6>Edit Profile</h6>
                          <hr className="featurette-divider" />
                          <Form.Group
                            className="users-page__assign-task"
                            controlId="firstNameValue"
                          >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter First Name"
                              value={selectedUser.first_name}
                              onChange={(event) => {
                                setSelectedUser({
                                  ...selectedUser,
                                  first_name: event.target.value,
                                });
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a first name.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="users-page__assign-task"
                            controlId="lastNameValue"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Last Name"
                              value={selectedUser.last_name}
                              onChange={(event) => {
                                setSelectedUser({
                                  ...selectedUser,
                                  last_name: event.target.value,
                                });
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide last name.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="users-page__assign-task"
                            controlId="emailValue"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Email"
                              value={selectedUser.email}
                              onChange={(event) => {
                                setSelectedUser({
                                  ...selectedUser,
                                  email: event.target.value,
                                });
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide an email.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="users-page__assign-task"
                            controlId="phoneValue"
                          >
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Phone"
                              value={selectedUser.phone}
                              onChange={(event) => {
                                setSelectedUser({
                                  ...selectedUser,
                                  phone: event.target.value,
                                });
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a phone.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="users-page__assign-task"
                            controlId="addressValue"
                          >
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Address"
                              value={selectedUser.address}
                              onChange={(event) => {
                                setSelectedUser({
                                  ...selectedUser,
                                  address: event.target.value,
                                });
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide an address.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Role Access</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="sort"
                              id="sort"
                              value={roleSortBy}
                              onChange={(event) => {
                                handleRoleSortBy(event);
                              }}
                            >
                              <option value={``}>-</option>
                              <option value={`admin`}>Admin</option>
                              <option value={`project manager`}>
                                Project Manager
                              </option>
                              <option value={`user`}>User</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Classification</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="sort"
                              id="sort"
                              value={classificationSortBy}
                              onChange={(event) => {
                                handleClassificationSortBy(event);
                              }}
                            >
                              <option value={``}>-</option>
                              <option value={`designer`}>Designer</option>
                              <option value={`drafter`}>Drafter</option>
                              <option value={`engineer`}>Engineer</option>
                            </Form.Select>
                          </Form.Group>
                          <Button
                            type="submit"
                            className="users-page__buttons-save"
                            onClick={() => {
                              // handleEditProfile(Number(UserID));
                              // window.location.reload();
                            }}
                          >
                            Save
                          </Button>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleCloseEditModal}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Modal
                      show={showDeleteModal}
                      onHide={handleCloseDeleteModal}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Delete Profile</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                          Are you sure you want to delete{" "}
                          <strong>{`${selectedUser.first_name} ${selectedUser.last_name}`}</strong>
                          ? This action cannot be undone.
                        </p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          onClick={() => {
                            handleDelete(selectedUser.id);
                          }}
                          variant="danger"
                        >
                          Delete
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={handleCloseDeleteModal}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Card.Body>
                </Card>
              </Col>
            )
          )}
      </Row>
    </main>
  );
};

export default Users;
