import "./Signup.scss";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { postSignup } from "../../apiUtil/apiUtil";

const Signup = () => {
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      await postSignup(
        event.target.email.value,
        event.target.password.value,
        event.target.first_name.value,
        event.target.last_name.value,
        event.target.phone.value,
        event.target.address.value
      );
      navigate("/Login");
    } catch (error: any) {
      event.target.reset();
      setError(error.response.data.message);
    }
  };

  return (
    <main className="signup-page">
      <div className="signup-page__sub-section">
        <div className="signup-page__sub-container d-flex align-items-center py-4 bg-body-tertiary">
          <form onSubmit={handleSubmit} className="signup-page__form">
            <h1 className="signup-page__sub-header">Enter your info</h1>
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingFirstName"
                placeholder="First Name"
                name="first_name"
                required
              />
              <label>First Name</label>
            </div>
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingLaStName "
                placeholder="Last Name"
                name="last_name"
                required
              />
              <label>Last Name</label>
            </div>
            <div className="form-floating">
              <input
                type="tel"
                className="form-control"
                id="floatingPhone "
                placeholder="Phone"
                name="phone"
                required
              />
              <label>Phone</label>
            </div>
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingAddress "
                placeholder="Address"
                name="address"
                required
              />
              <label>Address</label>
            </div>
            <div className="form-floating">
              <input
                type="email"
                className="form-control"
                id="floatingEmail "
                placeholder="Email"
                name="email"
                required
              />
              <label>Email</label>
            </div>
            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="floatingPassword "
                placeholder="Password"
                name="password"
                required
              />
              <label>Password</label>
            </div>

            <button
              type="submit"
              value="Sign up"
              className="signup__button btn btn-primary w-100 py-2"
            >
              Sign Up
            </button>
            <p>
              Have an account? <Link to="/Login">Log in</Link>
            </p>
            <p className="signup-page__error">{error}</p>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Signup;
