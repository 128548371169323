import "./Profile.scss";
import { useState, useEffect } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import {
  getProfile,
  patchProfile,
  editProfile,
  deleteProfile,
  passwordProfile,
} from "../../apiUtil/apiUtil";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import userIcon from "../../assets/icons/user.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import { Modal } from "react-bootstrap";

const Profile = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [user, setUser] = useState<any | null>(null);
  const [password, setPassword] = useState<any | null>({
    current: "",
    new: "",
  });
  const [errorPassword, setErrorPassword] = useState<any | null>({
    error: "",
    errorState: false,
  });
  const [failedAuth, setFailedAuth] = useState<boolean>(false);
  const [file, setFile] = useState(null);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [passwordValidated, setPasswordValidated] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { UserID } = useParams();
  const Navigate = useNavigate();

  const handleUpload = async (event: any) => {
    // event.preventDefault();
    const token = sessionStorage.getItem("token");
    // console.log(file);
    try {
      await patchProfile(token, file);
    } catch (error) {
      // console.log(error);
    }
  };

  const handleFile = (event: any) => {
    setFile(event.target.files[0].name);
  };

  // Re-render each time UserID in URL changes
  useEffect(() => {
    const getProfileFunction = async () => {
      const token: string | null = sessionStorage.getItem("token");

      // Checks if session token exists. If not, display Login link
      if (!token) {
        setFailedAuth(true);
      }

      try {
        // Retrieve profile data
        const { data } = await getProfile(token);
        // console.log(data);
        // Checks if token is expired
        if (data.error === "token expired") {
          sessionStorage.removeItem("token");
          Navigate("/Login");
        }
        // If user tries to manual change :UserID in URL, display Login link
        if (Number(UserID) !== data.id) {
          setFailedAuth(true);
        } else {
          setFailedAuth(false);
        }
        setUser(data);
      } catch (error) {
        // console.log(error);
        // Navigate("/Login");
      }
    };
    getProfileFunction();
  }, [UserID]);

  const handleCloseEditModal = async () => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Retrieve profile data
      const { data } = await getProfile(token);
      // console.log(data);
      // Checks if token is expired
      if (data.error === "token expired") {
        sessionStorage.removeItem("token");
        Navigate("/Login");
      }
      // If user tries to manual change :UserID in URL, display Login link
      if (Number(UserID) !== data.id) {
        setFailedAuth(true);
      } else {
        setFailedAuth(false);
      }
      setUser(data);
      setValidated(false);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
    setShowEditModal(false);
  };
  const handleShowEditModal = () => setShowEditModal(true);
  const handleShowPasswordModal = () => setShowPasswordModal(true);
  const handleClosePasswordModal = () => {
    setPassword({ current: "", new: "" });
    setErrorPassword({ error: "", errorState: false });
    setPasswordValidated(false);
    setShowPasswordModal(false);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);

  const handleEditProfile = async (id: number) => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Retrieve profile data
      await editProfile(
        token,
        Number(UserID),
        user.first_name,
        user.last_name,
        user.email,
        user.phone,
        user.address,
        user.role_access,
        user.classication_code
      );
      const { data } = await getProfile(token);
      setUser(data);
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handlePasswordProfile = async (id: number) => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Retrieve profile data
      const message: any = await passwordProfile(
        token,
        Number(UserID),
        password.current,
        password.new
      );
      console.log(message);
      const { data } = await getProfile(token);
      setUser(data);
      setPassword({ current: "", new: "" });
      setPasswordValidated(false);
      setShowPasswordModal(false);
    } catch (error: any) {
      // console.log(error.response.data);
      setPasswordValidated(true);
      setErrorPassword({
        error: error.response.data.error,
        errorState: error.response.data.errorState,
      });
      // setErrorPassword(error.response.data);
      // Navigate("/Login");
    }
  };

  const handleDelete = async () => {
    const token: string | null = sessionStorage.getItem("token");

    // Checks if session token exists. If not, display Login link
    if (!token) {
      setFailedAuth(true);
    }

    try {
      // Retrieve profile data
      await deleteProfile(token, Number(UserID));
      Navigate("/Login");
    } catch (error) {
      // console.log(error);
      // Navigate("/Login");
    }
  };

  const handleSubmit = (event: any, id: number) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      handleEditProfile(Number(id));
      setValidated(false);
      setShowEditModal(false);
    } else {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handlePasswordSubmit = (event: any, id: number) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      handlePasswordProfile(Number(id));
    } else {
      setPasswordValidated(true);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  if (!user) {
    return <main className="profile-page">Loading ...</main>;
  }

  if (failedAuth) {
    return (
      <main className="profile-page">
        <p>You must be logged in to see this page.</p>
        <Link to={"/Login"}>Log In</Link>
      </main>
    );
  }

  // console.log(user);

  return (
    <main className="profile-page">
      <Row xs={1} md={2} className="g-4 profile-page__card">
        {Array.from({ length: 1 }).map((_, idx) => (
          <Col key={idx}>
            <Card>
              <div className="profile-page__container">
                <Card.Img
                  variant="top"
                  src={
                    user.profile === null
                      ? userIcon
                      : `${API_URL}/${user.profile}`
                  }
                  className="profile-page__img"
                />
              </div>
              <Card.Body>
                <Card.Title>Your Profile</Card.Title>
                <Card.Text>
                  Name: {user.first_name} {user.last_name}
                </Card.Text>
                <Card.Text>Email: {user.email}</Card.Text>
                <Card.Text>Phone: {user.phone}</Card.Text>
                <Card.Text>Address: {user.address}</Card.Text>

                <p>Click on the "Upload" button to upload a profile picture:</p>
                <Form
                  noValidate
                  action={`${API_URL}/users/img/${UserID}`}
                  method="POST"
                  encType="multipart/form-data"
                  onSubmit={handleUpload}
                >
                  {/* <form
                    action={`${API_URL}/users/img/${UserID}`}
                    method="POST"
                    encType="multipart/form-data"
                    onSubmit={handleUpload}
                  > */}
                  <Form.Group className="position-relative mb-3">
                    <Form.Label>File</Form.Label>
                    <Form.Control
                      type="file"
                      required
                      name="avatar"
                      onChange={handleFile}
                      className="profile-page__input"
                      // onChange={handleChange}
                      // isInvalid={!!errors.file}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      {/* {errors.file} */}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {/* <input
                    type="file"
                    name="avatar"
                    onChange={handleFile}
                    className="profile-page__input"
                  /> */}
                  <Button type="submit">Upload</Button>
                  {/* </form> */}
                </Form>
                <hr className="featurette-divider" />
                <div className="profile-page__buttons">
                  <Button
                    onClick={handleShowPasswordModal}
                    className="profile-page__buttons-password"
                  >
                    Change Password
                  </Button>
                  <Button onClick={handleShowEditModal}>Edit Profile</Button>
                  <Button
                    onClick={handleShowDeleteModal}
                    variant="danger"
                    className="profile-page__buttons-danger"
                  >
                    Delete Profile
                  </Button>
                </div>
                <Modal
                  show={showPasswordModal}
                  onHide={handleClosePasswordModal}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form
                      noValidate
                      validated={passwordValidated}
                      onSubmit={(e) => {
                        handlePasswordSubmit(e, Number(UserID));
                      }}
                    >
                      <hr className="featurette-divider" />
                      <h6>Change Password</h6>
                      <hr className="featurette-divider" />
                      <Form.Group
                        className="profile-page__assign-task"
                        controlId="currentPasswordValue"
                      >
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter Current Password"
                          value={password.current}
                          onChange={(event) => {
                            setPassword({
                              ...password,
                              current: event.target.value,
                            });
                            setErrorPassword({ error: "", errorState: false });
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide current passsword
                        </Form.Control.Feedback>
                        {errorPassword.errorState && (
                          <div className="profile-page__error">
                            {errorPassword.error}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="profile-page__assign-task"
                        controlId="newPasswordValue"
                      >
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter New Password"
                          value={password.new}
                          onChange={(event) => {
                            setPassword({
                              ...password,
                              new: event.target.value,
                            });
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide new password
                        </Form.Control.Feedback>
                        <Button
                          type="submit"
                          className="profile-page__buttons-save"
                        >
                          Save
                        </Button>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleClosePasswordModal}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={showEditModal} onHide={handleCloseEditModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={(e) => {
                        handleSubmit(e, Number(UserID));
                      }}
                    >
                      <hr className="featurette-divider" />
                      <h6>Edit Profile</h6>
                      <hr className="featurette-divider" />
                      <Form.Group
                        className="profile-page__assign-task"
                        controlId="firstNameValue"
                      >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter First Name"
                          value={user.first_name}
                          onChange={(event) => {
                            setUser({
                              ...user,
                              first_name: event.target.value,
                            });
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a first name.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="profile-page__assign-task"
                        controlId="lastNameValue"
                      >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Last Name"
                          value={user.last_name}
                          onChange={(event) => {
                            setUser({
                              ...user,
                              last_name: event.target.value,
                            });
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide last name.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="profile-page__assign-task"
                        controlId="emailValue"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Email"
                          value={user.email}
                          onChange={(event) => {
                            setUser({
                              ...user,
                              email: event.target.value,
                            });
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide an email.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="profile-page__assign-task"
                        controlId="phoneValue"
                      >
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Phone"
                          value={user.phone}
                          onChange={(event) => {
                            setUser({
                              ...user,
                              phone: event.target.value,
                            });
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a phone.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="profile-page__assign-task"
                        controlId="addressValue"
                      >
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Address"
                          value={user.address}
                          onChange={(event) => {
                            setUser({
                              ...user,
                              address: event.target.value,
                            });
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide an address.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button
                        type="submit"
                        className="profile-page__buttons-save"
                      >
                        Save
                      </Button>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Delete Profile</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Are you sure you want to delete{" "}
                      <strong>{`${user.first_name} ${user.last_name}`}</strong>?
                      This action cannot be undone.
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleDelete} variant="danger">
                      Delete
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={handleCloseDeleteModal}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </main>
  );
};

export default Profile;
